import {
  FaChartBar,
  FaCogs,
  FaMapMarkerAlt,
  FaPencilAlt,
  FaPhoneVolume,
  FaRegClock,
  FaRegEnvelope,
  FaSms,
} from "react-icons/fa";

export const navbarData = [
  {
    id: 1,
    title: "Sunday - Friday",
    subTitle: "9am - 5pm",
    icon: <FaRegClock />,
  },
  {
    id: 2,
    title: "Call for help",
    subTitle: "+92 3345469946",
    icon: <FaPhoneVolume />,
  },
  {
    id: 3,
    title: "Mail to us",
    subTitle: "otawixsolution@gmail.com",
    icon: <FaRegEnvelope />,
  },
  {
    id: 4,
    title: "Our Address",
    subTitle: "Egerton Road Lahore",
    icon: <FaMapMarkerAlt />,
  },
];

export const OurServiceData = [
  {
    id: 1,
    title: "B2B Platform",
    subTitle: `B2B Platform B2B travel portal is an online booking engine with flights, hotels, insurance, transfers, sightseeing and packages modules for travel agencies / agents. It's also known as IBE`,
    icon: <FaPencilAlt />,
  },
  {
    id: 2,
    title: "B2C Website",
    subTitle: `B2C travel portal is an online booking engine with flights, hotels, transfers, sightseeing and packages modules. It's also known as IBE.It can be integrated with leading GDS`,
    icon: <FaCogs />,
  },
  {
    id: 3,
    title: "Custom Design",
    subTitle: ` Custom Design For a B2C travel agents sales through their website with their own design, offering a solution through which they can go online and automate their business.`,
    icon: <FaChartBar />,
  },
  {
    id: 4,
    title: "SEO/SMM Marketing",
    subTitle: `Grursus mal suada lisis Lorem ipsum a ametion consectetur elit fadolorit to the consectetur more elit.`,
    icon: <FaSms />,
  },
];



export const airlines = [
  "/assets/images/air-line-logos/british.jpeg",
  "/assets/images/air-line-logos/flydubai.jpeg",
  "/assets/images/air-line-logos/hitit.png",
  "/assets/images/air-line-logos/itehad.jpeg",
  "/assets/images/air-line-logos/logo-galileo.png",
  "/assets/images/air-line-logos/logo-hotelbeds.png",
  "/assets/images/air-line-logos/logo-rezlive.png",
  "/assets/images/air-line-logos/logo-travellanda.png",
  "/assets/images/air-line-logos/logo-uic.png",
  "/assets/images/air-line-logos/screne.jpeg",
];
